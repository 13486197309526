import type { NuxtError } from '#app'
import { useQueryClient } from '@tanstack/vue-query'
import { generateIdPathMap, type INodeGroup } from '@manager'
import { ApiError } from '@shared'

type QueryKey = [string, string, string, string, number]

const fetchLeaseCardSections = (divisionId: string, fieldGroupId: number) => {
  return fetchAPI<INodeGroup>(
    `/companies/${divisionId}/card/configuration/${fieldGroupId}`,
  )
}

export const useLeaseCardSections = async (
  divisionId: string,
  fieldGroupId: number,
) => {
  const { idPathByPage } = storeToRefs(useManagerStore())
  const queryClient = useQueryClient()

  return queryClient
    .ensureQueryData({
      queryKey: [
        'companies',
        divisionId,
        'card',
        'configuration',
        fieldGroupId,
      ] as QueryKey,
      queryFn: () => fetchLeaseCardSections(divisionId, fieldGroupId),
    })
    .then((data) => {
      if (!data)
        throw createError({
          data: {
            detail: 'Missing group configuration.',
          },
        })
      idPathByPage.value.set(fieldGroupId, generateIdPathMap(data))
      return data
    })
    .catch((e) => {
      throw createError({
        statusMessage:
          (e as ApiError | NuxtError).statusMessage ||
          'An error occurred when fetching the lease',
        statusCode: e instanceof ApiError ? e.response?.status : undefined,
        cause: e,
        fatal: true,
        data: (e as NuxtError).data,
      })
    })
}

export const usePrefetchLeaseCardSections = (divisionId: string) => {
  const queryClient = useQueryClient()

  return async (fieldGroupId: number) => {
    await queryClient.prefetchQuery({
      queryKey: [
        'companies',
        divisionId,
        'card',
        'configuration',
        fieldGroupId,
      ] as QueryKey,
      queryFn: () => fetchLeaseCardSections(divisionId, fieldGroupId),
    })
  }
}
